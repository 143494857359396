import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

// const getTokensFromVemoAPI = async () => {
//   const fetchTokensURL = await fetch("https://vemoswap.vemo.world/admin/api.php?tokens")
//   const res = await fetchTokensURL.json();


//   res.map( async (items: any) => {
//     const preItem = {
//         symbol: items.symbol,
//         address: {
//           56: items.address,
//           97: items.address,
//         },
//         decimals: items.decimals,
//         projectLink: items.projectLink,
//     }
//     console.log(preItem)
//   })
// }

// getTokensFromVemoAPI()

// const vemoPools = async () => {
//   const vemoPoolsURL = await fetch("https://vemoswap.vemo.world/admin/api.php?pools")
//   const resPools = await vemoPoolsURL.json()

//   const preVemoPools: PoolConfig[] = []

//   resPools.forEach((poolsList:any) => {
//     const prePollsInMap =
//     {
//       sousId: poolsList.sousId,
//       stakingToken: poolsList.stakingToken,
//       earningToken: poolsList.earningToken,
//       contractAddress: {
//         97: '',
//         56: poolsList.contractAddress["56"],
//       },
//       poolCategory: PoolCategory.CORE,
//       harvest: poolsList.harvest,
//       tokenPerBlock: poolsList.tokenPerBlock,
//       sortOrder: poolsList.sortOrder,
//       isFinished: poolsList.isFinished,
//       enableEmergencyWithdraw: true
//     }
//   })
//   return preVemoPools
// }
const pools: PoolConfig[] = [
  {
    sousId: 0,
    stakingToken: tokens.cake, // cake
    earningToken: tokens.cake, // cake
    contractAddress: {
      97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a', // 0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E', // 0x73feaa1eE314F8c655E354234017bE2193C9E24E
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10', // 10
    sortOrder: 1, // 999
    isFinished: false,
  },
  //   {
//     sousId: 1, // 157
//     stakingToken: tokens.vemo,
//     earningToken: tokens.vemo,
//     contractAddress: {
//       97: '',
//       56: '0x56d68687f73Fc0D291fEec817ccE18cf02777d79',
//     },
//     poolCategory: PoolCategory.CORE,
//     harvest: true,
//     tokenPerBlock: '1', // 9 Digit Calculate By GWei | 0.0025 = 0.0000000000025 GWei 
//     sortOrder: 2, // 999
//     isFinished: false,
//   },
]

export const getVemoPools = async () => {
  const preData = [ {
    sousId: 0,
    stakingToken: tokens.cake, // cake
    earningToken: tokens.cake, // cake
    contractAddress: {
      97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a', // 0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E', // 0x73feaa1eE314F8c655E354234017bE2193C9E24E
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10', // 10
    sortOrder: 1, // 999
    isFinished: false,
  }]
  try{
  const vemoPoolsURL = await fetch("https://vemoswap.vemo.world/admin/api.php?pools")
  const resPools = await vemoPoolsURL.json()

  // for(let i = 0; i<=resPools.length-1; i++){
  //   pools.push(resPools[i])
  // }


  resPools.forEach((poolsList) => {
    

 
    const prePollsInMap =
    {
      sousId: poolsList.sousId,
      stakingToken: poolsList.stakingToken,
      earningToken: poolsList.earningToken,
      contractAddress: {
        97: '',
        56: poolsList.contractAddress["56"],
      },
      poolCategory: PoolCategory.CORE,
      harvest: poolsList.harvest,
      tokenPerBlock: poolsList.tokenPerBlock,
      sortOrder: poolsList.sortOrder,
      isFinished: poolsList.isFinished,
    }
    preData.push(prePollsInMap)
  
  })
  console.log(preData)
  return preData
}
catch(error){
console.log(error)
return preData
}
}


// fetch("https://vemoswap.vemo.world/admin/api.php?pools")
//   .then((res) => res.json())
//   .then((data) => {
//     for(let i = 0; i<=data.length-1; i++){
//     pools.push(data[i])
//     }
//   })
// // getVemoPools()
// console.log(pools)

// const pools: PoolConfig[] = [
//   {
//     sousId: 0,
//     stakingToken: tokens.cake, // cake
//     earningToken: tokens.cake, // cake
//     contractAddress: {
//       97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a', // 0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a
//       56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E', // 0x73feaa1eE314F8c655E354234017bE2193C9E24E
//     },
//     poolCategory: PoolCategory.CORE,
//     harvest: true,
//     tokenPerBlock: '10', // 10
//     sortOrder: 1, // 999
//     isFinished: false,
//   },
// /* ===================== */
//   // https://bscscan.com/unitconverter?wei=2500000
//   {
//     sousId: 1, // 157
//     stakingToken: tokens.vemo,
//     earningToken: tokens.vemo,
//     contractAddress: {
//       97: '',
//       56: '0x56d68687f73Fc0D291fEec817ccE18cf02777d79',
//     },
//     poolCategory: PoolCategory.CORE,
//     harvest: true,
//     tokenPerBlock: '1', // 9 Digit Calculate By GWei | 0.0025 = 0.0000000000025 GWei 
//     sortOrder: 2, // 999
//     isFinished: false,
//   },
//   {
//     sousId: 2,
//     stakingToken: tokens.vemobnb,
//     earningToken: tokens.mtm,
//     contractAddress: {
//       97: '',
//       56: '0x812561639eD47927320D4F20485B707815C5a87B', // '0x1823d18fDdB530Cb41C106F928795b5D36E3337b',
//     },
//     poolCategory: PoolCategory.CORE,
//     harvest: true,
//     tokenPerBlock: '0.00025', // https://bscscan.com/unitconverter?wei=250000
//     sortOrder: 3, // 999
//     isFinished: false,
//   },
//   {
//     sousId: 3,
//     stakingToken: tokens.vemousdt,
//     earningToken: tokens.mtm,
//     contractAddress: {
//       97: '',
//       56: '0xc5F82f8e1F199d076F64FB3a26fB92926D7017C9', // '0x1823d18fDdB530Cb41C106F928795b5D36E3337b',
//     },
//     poolCategory: PoolCategory.CORE,
//     harvest: true,
//     tokenPerBlock: '0.00025', // https://bscscan.com/unitconverter?wei=2500000
//     sortOrder: 4,
//     isFinished: false,
//   },
//   {
//     sousId: 4,
//     stakingToken: tokens.wbnb,
//     earningToken: tokens.mtm, // well
//     contractAddress: {
//       97: '',
//       56: '0x219562Eb86E61A93Ac48f544397b7934C48df566',
//     },
//     poolCategory: PoolCategory.CORE,
//     harvest: true,
//     tokenPerBlock: '0.00025', // https://bscscan.com/unitconverter?wei=2500000000000000
//     sortOrder: 5, // 999
//     isFinished: false,
//   },
//   {
//     sousId: 5,
//     stakingToken: tokens.doge,
//     earningToken: tokens.mtm, // well
//     contractAddress: {
//       97: '',
//       56: '0xB8e7C02405A1df197Ed2A39A434A7c786A3BDbd9',
//     },
//     poolCategory: PoolCategory.CORE,
//     harvest: true,
//     tokenPerBlock: '0.00025', // https://bscscan.com/unitconverter?wei=2500000000000000
//     sortOrder: 6, // 999
//     isFinished: false,
//   },
//   {
//     sousId: 6,
//     stakingToken: tokens.btcb,
//     earningToken: tokens.mtm, // well
//     contractAddress: {
//       97: '',
//       56: '0x53A7E81c3C7AF091d18Abe66A50a24274cAc7094',
//     },
//     poolCategory: PoolCategory.CORE,
//     harvest: true,
//     tokenPerBlock: '0.00025', // https://bscscan.com/unitconverter?wei=2500000000000000
//     sortOrder: 7, // 999
//     isFinished: false,
//   },
//   {
//     sousId: 7,
//     stakingToken: tokens.xrp,
//     earningToken: tokens.mtm, // well
//     contractAddress: {
//       97: '',
//       56: '0x20f235DDc363beA05999Ebb64263f43C91e63f49',
//     },
//     poolCategory: PoolCategory.CORE,
//     harvest: true,
//     tokenPerBlock: '0.00025', // https://bscscan.com/unitconverter?wei=2500000000000000
//     sortOrder: 8, // 999
//     isFinished: false,
//   },
//   {
//     sousId: 8,
//     stakingToken: tokens.vsnt,
//     earningToken: tokens.mtm, // well
//     contractAddress: {
//       97: '',
//       56: '0xeD0066ae8a1AB62bD72266e953FE67b396F9774d',
//     },
//     poolCategory: PoolCategory.CORE,
//     harvest: true,
//     tokenPerBlock: '0.75', // https://bscscan.com/unitconverter?wei=2500000000000000
//     sortOrder: 9,
//     isFinished: false,
//   },
//   {
//     sousId: 9,
//     stakingToken: tokens.vsntbnb,
//     earningToken: tokens.mtm, // well
//     contractAddress: {
//       97: '',
//       56: '0xC32cC6690b5068124f8A94ba1804944A2593C491',
//     },
//     poolCategory: PoolCategory.CORE,
//     harvest: true,
//     tokenPerBlock: '0.00025', // https://bscscan.com/unitconverter?wei=2500000000000000
//     sortOrder: 10,
//     isFinished: false,
//   },
//   {
//     sousId: 11,
//     stakingToken: tokens.eth,
//     earningToken: tokens.mtm, // well
//     contractAddress: {
//       97: '',
//       56: '0x1951f84B9e9634C1CA6a7FBF84aBA6DD6D6C1580',
//     },
//     poolCategory: PoolCategory.CORE,
//     harvest: true,
//     tokenPerBlock: '0.00025', // https://bscscan.com/unitconverter?wei=2500000000000000
//     sortOrder: 12, // 999
//     isFinished: false,
//   },
//   {
//     sousId: 12,
//     stakingToken: tokens.fxpay,
//     earningToken: tokens.mtm, // well
//     contractAddress: {
//       97: '',
//       56: '0x90CEC4C0B2101A6B68B76024668AbfFbe8862C29',
//     },
//     poolCategory: PoolCategory.CORE,
//     harvest: true,
//     tokenPerBlock: '0.02', // https://bscscan.com/unitconverter?wei=2500000000000000
//     sortOrder: 13, // 999
//     isFinished: false,
//   },
//   {
//     sousId: 13,
//     stakingToken: tokens.fxpaybnb,
//     earningToken: tokens.mtm, // well
//     contractAddress: {
//       97: '',
//       56: '0x90CEC4C0B2101A6B68B76024668AbfFbe8862C29',
//     },
//     poolCategory: PoolCategory.CORE,
//     harvest: true,
//     tokenPerBlock: '0.00025', // https://bscscan.com/unitconverter?wei=2500000000000000
//     sortOrder: 14, // 999
//     isFinished: false,
//   },
//   {
//     sousId: 14,
//     stakingToken: tokens.bch,
//     earningToken: tokens.mtm, // well
//     contractAddress: {
//       97: '',
//       56: '0x342693c487736d43265B6D2e4a080a1A408F04B5',
//     },
//     poolCategory: PoolCategory.CORE,
//     harvest: true,
//     tokenPerBlock: '0.00025', // https://bscscan.com/unitconverter?wei=2500000000000000
//     sortOrder: 15, // 999
//     isFinished: false,
//   },
// ]

// const _tokens = {
//   vemo: {
//     symbol: 'VEMO',
//     address: {
//       56: '0xc8874377aB1Fd172bC4B0Ed672a188bdAC1F20ca', // Token Address
//       97: '',
//     },
//     decimals: 9,
//     projectLink: 'https://pancakeswap.finance/swap?outputCurrency=0xc8874377aB1Fd172bC4B0Ed672a188bdAC1F20ca',
//   },
//   vemobnb: {
//     symbol: 'VEMO-BNB LP',
//     address: {
//       56: '0xd46d84fd36d3a5fc2453d34b357c570a3e0214c6', // VEMO-BNB LP Address
//       97: '',
//     },
//     decimals: 18,
//     projectLink: 'https://pancakeswap.finance/add/BNB/0xc8874377aB1Fd172bC4B0Ed672a188bdAC1F20ca',
//   }
// }

// const _pools = [
//   {
//     sousId: 1, // 157
//     stakingToken: _tokens.vemo,
//     earningToken: _tokens.vemo,
//     contractAddress: {
//       97: '',
//       56: '0x56d68687f73Fc0D291fEec817ccE18cf02777d79',
//     },
//     poolCategory: PoolCategory.CORE,
//     harvest: true,
//     tokenPerBlock: '0.00308', // 9 Digit Calculate By GWei | 0.0025 = 0.0000000000025 GWei 
//     sortOrder: 2, // 999
//     isFinished: false,
//   },
//   {
//   sousId: 2,
//   stakingToken: _tokens.vemobnb,
//   earningToken: _tokens.vemo,
//   contractAddress: {
//     97: '',
//     56: '0x812561639eD47927320D4F20485B707815C5a87B', // '0x1823d18fDdB530Cb41C106F928795b5D36E3337b',
//   },
//   poolCategory: PoolCategory.CORE,
//   harvest: true,
//   tokenPerBlock: '0.00025', // https://bscscan.com/unitconverter?wei=250000
//   sortOrder: 3, // 999
//   isFinished: false,
// },
// ]
// _pools.forEach(poolsItem => {
//   pools.push(poolsItem)
// });


/* Vemo Pools */
    
  const fetchVemoPools = async () => {
    const fetchURL = await fetch("https://vemoswap.vemo.world/admin/api.php?pools");
    const vemoPoolTokens = await fetchURL.json();

    vemoPoolTokens.forEach((poolsItem:any) => {
      pools.push(poolsItem)
    });
    console.log(pools)
  }

  
  
/* Vemo Pools */

export default pools
