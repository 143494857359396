import React from 'react'
import BigNumber from 'bignumber.js'
import { getBalanceNumber } from 'utils/formatBalance'
import { CardBody, Flex, Text, CardRibbon, IconButton, CalculateIcon, useModal } from '@pancakeswap/uikit'
import Balance from 'components/Balance'
import UnlockButton from 'components/UnlockButton'
import { useTranslation } from 'contexts/Localization'
import { BIG_ZERO } from 'utils/bigNumber'
import { Pool } from 'state/types'
import { useBusdPriceFromToken } from 'state/hooks'
import ApyCalculatorModal from 'components/ApyCalculatorModal'
import { BASE_EXCHANGE_URL } from 'config'
import { StyledCard, StyledCardInner } from './StyledCard'
import CardFooter from './CardFooter'
import StyledCardHeader from './StyledCardHeader'
import CardActions from './CardActions'
import AprRow from './AprRow'


const PoolCard: React.FC<{ pool; account: string }> = ({ pool, account }) => {
  const { sousId, stakingToken, earningToken, isFinished, userData, tokenPerBlock, totalStaked } = pool
  const { t } = useTranslation()
  const stakedBalance = userData?.stakedBalance ? new BigNumber(userData.stakedBalance) : BIG_ZERO
  const accountHasStakedBalance = stakedBalance.gt(0)
  
  
  const isCACPool = earningToken.symbol === "CAC"
  const isCACBNBPool = earningToken.symbol !== "CAC-BNB LP"
  const isNotCACPool = earningToken.symbol !== "VEMO"
  const getTotalStakedBalance = () => {
    if (isNotCACPool) {
      const manualCakeTotalMinusAutoVault = new BigNumber(totalStaked)
      return getBalanceNumber(manualCakeTotalMinusAutoVault, stakingToken.decimals)
    }
    return getBalanceNumber(totalStaked, stakingToken.decimals)
  }

  const startAPR = ((parseFloat(tokenPerBlock)*(60 / 3)) * 60 * 24 * 365)
  const totalCurrentStaking = getTotalStakedBalance()
  const realDynamicAPR = (startAPR/totalCurrentStaking)*100
  const dynamicAPR = () => {
      switch(stakingToken.symbol){
        case "CAC" :
          return realDynamicAPR
        case "CAC-BNB LP" :
          return realDynamicAPR/100
        case "wCAC-CAC LP" :
          return realDynamicAPR/10
        case "CAC-BUSD LP" :
          return realDynamicAPR/100
        case "CAC-USDT LP" :
          return realDynamicAPR/20000
          default:
        return realDynamicAPR
      }
  }
  

  const earningTokenPrice = useBusdPriceFromToken(earningToken.symbol)
  const earningTokenPriceAsNumber = earningTokenPrice && earningTokenPrice.toNumber()

  const apyModalLink =
  stakingToken.address &&
  `${BASE_EXCHANGE_URL}/#/swap?outputCurrency=${stakingToken.address[process.env.REACT_APP_CHAIN_ID]}`

  const apyMyModalLink = () => {
    switch(stakingToken.symbol){
      case "VEMO" :
        return `${BASE_EXCHANGE_URL}/swap?outputCurrency=${stakingToken.address[process.env.REACT_APP_CHAIN_ID]}` // CAC
      case "VEMO-BNB LP" :
        return `${BASE_EXCHANGE_URL}/add/BNB/0xc8874377aB1Fd172bC4B0Ed672a188bdAC1F20ca`
      case "VEMO-USDT LP" :
        return `${BASE_EXCHANGE_URL}/add/0x55d398326f99059ff775485246999027b3197955/0xc8874377aB1Fd172bC4B0Ed672a188bdAC1F20ca`
        default:
      return stakingToken.projectLink;
    }
  }
  const [onMyPresentApyModal] = useModal(
    <ApyCalculatorModal
      tokenPrice={earningTokenPriceAsNumber}
      apr={dynamicAPR()}
      linkLabel={t('Get %symbol%', { symbol: stakingToken.symbol })}
      linkHref={apyMyModalLink()} // {apyMyModalLink() || BASE_EXCHANGE_URL}
      earningTokenSymbol={earningToken.symbol}
      // roundingDecimals={isHighValueToken ? 4 : 2}
      // compoundFrequency={compoundFrequency}
      // performanceFee={performanceFee}
    />,
  )
  const [onMyPresentApyModalDynamicAPR] = useModal(
    <ApyCalculatorModal
      tokenPrice={earningTokenPriceAsNumber}
      apr={realDynamicAPR}
      linkLabel={t('Get %symbol%', { symbol: stakingToken.symbol })}
      linkHref={apyMyModalLink() || BASE_EXCHANGE_URL} // {apyMyModalLink()}
      earningTokenSymbol={earningToken.symbol}
      // roundingDecimals={isHighValueToken ? 4 : 2}
      // compoundFrequency={compoundFrequency}
      // performanceFee={performanceFee}
    />,
  )

  return (
    <StyledCard
      isFinished={isFinished && sousId !== 0}
      ribbon={isFinished && <CardRibbon variantColor="textDisabled" text={t('Finished')} />}
    >
      <StyledCardInner>
        <StyledCardHeader
          isStaking={accountHasStakedBalance}
          earningTokenSymbol={earningToken.symbol}
          stakingTokenSymbol={stakingToken.symbol}
          isFinished={isFinished && sousId !== 0}
        />
        <CardBody style={{background:'#434853'}}>
          
          <Flex alignItems="center" justifyContent="space-between">
            <Flex alignItems="center">
              {t('APR :')}
            </Flex>
            <Flex>
                <Balance
                  fontSize="16px"
                  isDisabled={isFinished}
                  value={realDynamicAPR}
                  decimals={2}
                  unit="%"
                  bold
                />
                <IconButton onClick={onMyPresentApyModalDynamicAPR} variant="text" scale="sm">
                  <CalculateIcon color="textSubtle" width="18px" />
              </IconButton>
            </Flex>
          </Flex>

          
          {/* { 
          (earningToken.symbol === 'VEMO') ?
          (<AprRow pool={pool} />)
          :
          (<Flex alignItems="center" justifyContent="space-between">
          {t('APR :')}
            <Flex alignItems="center">
              <Balance
                fontSize="16px"
                isDisabled={isFinished}
                value={realDynamicAPR}
                decimals={2}
                unit="%"
                bold
              />
              <IconButton onClick={onMyPresentApyModal} variant="text" scale="sm">
                <CalculateIcon color="textSubtle" width="18px" />
            </IconButton>
            </Flex>

          </Flex>)
          } */}


            <Flex mt="24px" flexDirection="column" >
             
              {account ? (
                <CardActions pool={pool} stakedBalance={stakedBalance} />
              ) : (
                <>
                  <Text mb="10px" textTransform="uppercase" fontSize="12px" color="textSubtle" bold>
                    {t('Start earning')}
                  </Text>
                  <UnlockButton style={{background:'#E8222D'}}/>
                </>
              )}
            </Flex>
        </CardBody>
        <CardFooter pool={pool} account={account} />
      </StyledCardInner>
    </StyledCard>
  )
}

export default PoolCard
