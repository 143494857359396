import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Route, useRouteMatch, Redirect } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { Heading, Flex, Image } from '@pancakeswap/uikit'
import orderBy from 'lodash/orderBy'
import partition from 'lodash/partition'
import { useTranslation } from 'contexts/Localization'
import usePersistState from 'hooks/usePersistState'
import { usePools, useFetchCakeVault, useFetchPublicPoolsData } from 'state/hooks'
import FlexLayout from 'components/layout/Flex'
import Page from 'components/layout/Page'
// import PageHeader from 'components/PageHeader'
import PoolCard from './components/PoolCard'
// import CakeVaultCard from './components/CakeVaultCard'
import PoolTabButtons from './components/PoolTabButtons'
import BountyCard from './components/BountyCard'
import tokens from '../../config/constants/tokens'
import { PoolConfig, PoolCategory } from '../../config/constants/types'

const NUMBER_OF_POOLS_VISIBLE = 12

const Pools: React.FC = () => {
  const { path } = useRouteMatch()
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const pools = usePools(account)
  const [stakedOnly, setStakedOnly] = usePersistState(false, 'pancake_pool_staked')
  const [numberOfPoolsVisible, setNumberOfPoolsVisible] = useState(NUMBER_OF_POOLS_VISIBLE)
  const [observerIsSet, setObserverIsSet] = useState(false)
  const loadMoreRef = useRef<HTMLDivElement>(null)
  // const [vemoPools, setVemoPools] = useState([])
 
  const [finishedPools, openPools] = useMemo(() => partition(pools, (pool) => pool.isFinished), [pools])
  const stakedOnlyFinishedPools = useMemo(
    () => finishedPools.filter((pool) => pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)),
    [finishedPools],
  )
  console.log("stakedOnlyFinishedPools",stakedOnlyFinishedPools);
  
  const stakedOnlyOpenPools = useMemo(
    () => openPools.filter((pool) => pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)),
    [openPools],
  )
  const hasStakeInFinishedPools = stakedOnlyFinishedPools.length > 0

  const writeERROR = () => {
    window.location.href = "https://vemoswap.vemo.world";
  }
  let licenseStatus = false;

  if(window.location.hostname === "localhost") licenseStatus = true;
  if(window.location.hostname === "vemoswap.vemo.world") licenseStatus = true

  // if(licenseStatus === false) writeERROR()

  // This pool is passed explicitly to the cake vault
  // const cakePoolData = useMemo(() => openPools.find((pool) => pool.sousId === 0), [openPools])

  useFetchCakeVault()
  useFetchPublicPoolsData()

  useEffect(() => {
    const showMorePools = (entries) => {
      const [entry] = entries
      if (entry.isIntersecting) {
        setNumberOfPoolsVisible((poolsCurrentlyVisible) => poolsCurrentlyVisible + NUMBER_OF_POOLS_VISIBLE)
      }
    }

    if (!observerIsSet) {
      const loadMoreObserver = new IntersectionObserver(showMorePools, {
        rootMargin: '0px',
        threshold: 1,
      })
      loadMoreObserver.observe(loadMoreRef.current)
      setObserverIsSet(true)
    }
    
  }, [observerIsSet])

  // const getVemoPools = async () => {
  //   const vemoPoolsURL = await fetch("https://vemoswap.vemo.world/admin/api.php?pools")
  //   const resPools = await vemoPoolsURL.json()

  //   const preVemoPools: PoolConfig[] = []

  //   resPools.forEach((poolsList) => {
  //     const prePollsInMap =
  //     {
  //       sousId: poolsList.sousId,
  //       stakingToken: poolsList.stakingToken,
  //       earningToken: poolsList.earningToken,
  //       contractAddress: {
  //         97: '',
  //         56: poolsList.contractAddress["56"],
  //       },
  //       poolCategory: poolsList.poolCategory,
  //       harvest: poolsList.harvest,
  //       tokenPerBlock: poolsList.tokenPerBlock,
  //       sortOrder: poolsList.sortOrder,
  //       isFinished: poolsList.isFinished,
  //       enableEmergencyWithdraw: true
  //     }
  //     preVemoPools.push(prePollsInMap)
  //   })

  //   setVemoPools(preVemoPools)
  // }

  // useEffect(() => {
  //     getVemoPools()
  // }, [vemoPools])

  
  return (
    <>
      <Page>
        <PoolTabButtons
          stakedOnly={stakedOnly}
          setStakedOnly={setStakedOnly}
          hasStakeInFinishedPools={hasStakeInFinishedPools}
        />
        <FlexLayout>
          <Route exact path={`${path}`}>
            <>
              {/* <CakeVaultCard pool={cakePoolData} showStakedOnly={stakedOnly} /> */}
              {stakedOnly
                ? orderBy(stakedOnlyOpenPools, ['sortOrder'])
                    .slice(1, numberOfPoolsVisible)
                    .map((pool) => <PoolCard key={pool.sousId} pool={pool} account={account} />)
                : orderBy(openPools, ['sortOrder'])
                    .slice(1, numberOfPoolsVisible)
                    .map((pool) =>  <PoolCard key={pool.sousId} pool={pool} account={account} /> )}


              {/* { 
                vemoPools.length > 0 
                  ? vemoPools.map((_pools) => (
                    <PoolCard key={_pools.sousId} pool={_pools} account={account} />
                    
                  ))
                  : ""
              } */}
            </>
          </Route>
          <Route path={`${path}/history`}>
            {stakedOnly
              ? orderBy(stakedOnlyFinishedPools, ['sortOrder'])
                  .slice(0, numberOfPoolsVisible)
                  .map((pool) => <PoolCard key={pool.sousId} pool={pool} account={account} />)
              : orderBy(finishedPools, ['sortOrder'])
                  .slice(0, numberOfPoolsVisible)
                  .map((pool) => <PoolCard key={pool.sousId} pool={pool} account={account} />)}
          </Route>
        </FlexLayout>
        <div ref={loadMoreRef} />
        <Image src="/images/gct-banner.png" alt="VEMO illustration" width={1080} height={600} responsive />
      </Page>
    </>
  )
}

export default Pools
